<template>
  <div id="app">
    <keep-alive :include="cachedViews">
        <router-view v-if="this.$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view  v-if="!this.$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: 'app',
  data() {
    return {
      cachedViews: []
    }
  },
  watch: {
    //监听动态缓存动态赋值控制页面是否缓存
    $route: {
      handler:function(to,from){
        this.cachedViews = this.$store.state.cacheView;
      }
    }
  }
}
</script>
<style>
</style>
