import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
     //报错页
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/notFound/notFound.vue')
  },
  {
    //首页
    path: '/home/:code',
    name: 'Home',
    component: () => import('@/views/home/home.vue'),
    meta: {
      keepAlive: true, // 加上这个需要缓存
      title: '招商平台'
    }
  },
  {
    //首页列表更多页
    path: '/more/:code/:type',
    name: 'More',
    component: () => import('@/views/more/more.vue'),
    meta: {
      
    }
  },
  {
    //投资动态详情
    path: '/newsDetail/:type/:id',
    name: 'NewsDetail',
    component: () => import('@/views/newsDetail/newsDetail.vue')
  },
  {
    //园区详情
    path: '/park/:code/:id',
    name: 'Park',
    component: () => import('@/views/investmentDetails/park.vue')
  },
  {
    //地块详情
    path: '/massif/:code/:id',
    name: 'Massif',
    component: () => import('@/views/investmentDetails/massif.vue')
  },
  {
    //厂房详情
    path: '/workshop/:code/:id',
    name: 'Workshop',
    component: () => import('@/views/investmentDetails/workshop.vue')
  },
  {
    //项目详情
    path: '/project/:code/:id',
    name: 'Project',
    component: () => import('@/views/investmentDetails/project.vue')
  },
  {
    //楼宇详情
    path: '/building/:code/:id',
    name: 'Building',
    component: () => import('@/views/investmentDetails/building.vue')
  },
  {
    //众创详情
    path: '/popularEn/:code/:id',
    name: 'PopularEn',
    component: () => import('@/views/investmentDetails/popularEn.vue')
  },
  {
    //名企详情
    path: '/fep/:code/:id',
    name: 'FamousEnterprises',
    component: () => import('@/views/investmentDetails/famousEnterprises.vue')
  },
  {
    //商协会详情
    path: '/bac/:code/:id',
    name: 'BusinessAssociation',
    component: () => import('@/views/investmentDetails/businessAssociation.vue')
  },
  {
    //地图
    path: '/map/:code',
    name: 'Map',
    component: () => import('@/views/map/map.vue')
  },
  {
    //印象详情
    path: '/impDetail/:code/:type',
    name: 'ImpressionDetail',
    component: () => import('@/views/impressionDetail/impressionDetail.vue')
  },
  {
    //视频加图集
    path: '/vaDetail/:type/:id/:code',
    name: 'VideoatlasDetail',
    component: () => import('@/views/videoatlasDetail/videoatlasDetail.vue')
  },
  {
    //图文详情
    path: '/twDetail/:code/:type/:cate',
    name: 'TwDetail',
    component: () => import('@/views/twDetail/twDetail.vue')
  },
  {
    //图集详情
    path: '/picDetail/:code/:id/:titles',
    name: 'PicDetail',
    component: () => import('@/views/picDetail/picDetail.vue')
  },
  {
    //产业链详情
    path: '/chain/:code/:id/:titles',
    name: 'IndustrialChain',
    component: () => import('@/views/industrialChain/industrialChain.vue')
  },
]

const router = new VueRouter({
  //BASE_URL 根据环境动态变更路径
  base: process.env.BASE_URL,
  //hash模式下部署到服务器访问没问题，history就不行，需要在服务器上配置
  mode: 'history',
  routes
})

export default router
